import Div100vh from 'react-div-100vh';
import { useNotification, BBUIApp } from '@bbnpm/bb-ui-framework';
import useCheckBrowser from './common/checkBrowser';
import useDetectServiceWorkers from './common/detectServiceWorkers';
import {
  useSyncTabsOnUserAuthChange,
  UserContext,
  getUserFromSessionCookie,
} from './common/user';
import { isStorageAvailable } from './common/util';
import { RouterProvider } from 'react-router-dom';
import router from './router';

function App() {
  const notification = useNotification();

  useCheckBrowser((browserRecommendation) =>
    notification.addInfo({ message: browserRecommendation })
  );

  useDetectServiceWorkers();

  useSyncTabsOnUserAuthChange();

  const user = getUserFromSessionCookie();

  if (
    user &&
    isStorageAvailable('localStorage') &&
    !window.localStorage.getItem('ROOM_PREVIOUS_LOGIN')
  ) {
    // Remember that the user has logged in at least once on this browser
    window.localStorage.setItem('ROOM_PREVIOUS_LOGIN', 'true');
  }

  return (
    <UserContext.Provider value={user}>
      <BBUIApp activeTheme='dark'>
        <Div100vh style={{ display: 'flex', flexDirection: 'column' }}>
          <RouterProvider router={router} />
        </Div100vh>
      </BBUIApp>
    </UserContext.Provider>
  );
}

export default App;
