import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Button, useNotification, SplitButton } from '@bbnpm/bb-ui-framework';
import { device } from '../common/mediaQuery';
import { useNavigate } from 'react-router-dom';
import { inviteUserToRoom } from '../common/invitation';
import { copyToClipboard } from '../common/util';
import { RoomInfo } from '../types';
import { UserContext } from '../common/user';

interface JoinRoomButtonProps {
  selectedRoom: RoomInfo;
  openDialInModal: (val: boolean) => void;
}

export function JoinRoomButton(props: JoinRoomButtonProps) {
  const { selectedRoom, openDialInModal } = props;
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const notification = useNotification();

  const joinRoom = useCallback(() => {
    if (selectedRoom.roomId) {
      navigate(`/rooms/${selectedRoom.roomId}`);
    }
  }, [navigate, selectedRoom]);

  const getFullRoomUrl = useCallback(() => {
    if (!selectedRoom.roomId) {
      return '';
    }
    const url = new URL(`/rooms/${selectedRoom.roomId}`, window.location.href);
    return url.href;
  }, [selectedRoom]);

  return (
    <ButtonsContainer>
      <JoinSplitButton
        onClick={joinRoom}
        kind='primary'
        buttonProps={{
          'aria-label': 'Join Selected ROOM',
        }}
        disabled={!selectedRoom.roomId}
        menuItems={[
          {
            label: 'Join via phone',
            onSelect: () => {
              openDialInModal(true);
            },
          },
          {
            label: 'Join via another device',
            onSelect: () => {
              if (!user) {
                return;
              }
              inviteUserToRoom(user.uuid, selectedRoom.roomId, user.loginJwt);
              notification.addInfo({
                message:
                  'You can now join this meeting on another device at blproom.com.',
              });
            },
          },
        ]}
      >
        Join
      </JoinSplitButton>
      <Button
        kind='secondary'
        onClick={() => {
          const url = getFullRoomUrl();
          if (url) {
            copyToClipboard(url);
            notification.addInfo({
              message: 'Copied meeting web link.',
              timeout: 10000,
            });
          }
        }}
        aria-label='Copy a web link to the selected ROOM meeting'
        disabled={!selectedRoom.roomId}
      >
        Copy Link
      </Button>
    </ButtonsContainer>
  );
}
const ButtonsContainer = styled.div`
  align-self: flex-start;
  display: flex;
  margin: 12px;

  .bbui-button.secondary {
    margin-left: 12px;
  }

  @media ${device.tablet} {
    display: block;
    margin: 4px 0 16px 0;
    width: 100%;
    .bbui-splitbutton {
      padding: 0;
      width: 48%;
    }
    .bbui-splitbutton-buttonparent,
    .bbui-button.primary {
      flex-grow: 1;
      display: flex;
    }
    .bbui-button.primary {
      &.bbui-splitbutton-togglebutton {
        display: block;
        flex-grow: 0;
      }
    }
    .bbui-button.secondary {
      width: 48%;
      margin-left: 4%;
    }
  }
`;

const JoinSplitButton = styled(SplitButton)`
  text-transform: none;
`;
