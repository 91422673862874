import React, {
  useState,
  useCallback,
  useRef,
  ReactText,
  useEffect,
  useContext,
} from 'react';
import {
  Autocomplete,
  useAsyncAutocomplete,
  stringToHighlightedLabel,
  InputGroup,
  Icon,
  Input,
  FormField,
} from '@bbnpm/bb-ui-framework';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import {
  getPersonalRoom,
  getUsersAutoComplete,
  getParticipantCount,
} from '../common/api';
import { DropdownValueOption } from '@bbnpm/bb-ui-framework/BaseDropdown/BaseDropdown.types';
import { FormFieldProps } from '@bbnpm/bb-ui-framework/FormField/FormField.types';
import {
  GetParticipantCountResponse,
  GetPersonalRoomResponse,
  RoomInfo,
} from '../types';
import { device } from '../common/mediaQuery';
import { logger } from '../common/logger';
import { UserContext } from '../common/user';

interface UserInfo {
  name: string;
  uuid: string;
}

export default function RoomNameAutoComplete({
  selectedRoom,
  setSelectedRoom,
}: {
  selectedRoom: RoomInfo;
  setSelectedRoom: (roomInfo: RoomInfo) => void;
}) {
  const user = useContext(UserContext);
  const isTablet = useMediaQuery({ query: device.tablet });

  const userPersonalRoom = useRef<RoomInfo | null>(null);
  const autoCompleteElement = useRef<HTMLInputElement>(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [selectedUser, setSelectedUser] = useState<DropdownValueOption<
    ReactText,
    Record<string, unknown>
  > | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [{ onThrottledInputChange, ...rest }, { setOptions }] =
    useAsyncAutocomplete();
  const [validationState, setValidationState] = useState<FormFieldProps>({
    validationType: null,
    validationContent: '',
  });

  const onInput = useCallback(
    async (inputText: string) => {
      onThrottledInputChange(inputText);
      if (!inputText) {
        setOptions([], inputText);
        return;
      }
      const users = (await getUsersAutoComplete(
        inputText,
        user.loginJwt
      )) as UserInfo[];

      let retval: DropdownValueOption<ReactText, Record<string, unknown>>[] =
        [];
      if (Array.isArray(users)) {
        retval = users.map((user) => {
          return {
            label: user.name,
            value: user.uuid,
            meta: user as unknown as Record<string, unknown>,
          };
        });
        setOptions(
          [
            { label: 'PERSONAL ROOM', options: retval },
            // TODO: retrieve matched IB ROOMs
            // { label: 'IB ROOM', options: [] },
          ],
          inputText
        );
        setValidationState({
          validationType: null,
          validationContent: '',
        });
      } else {
        logger.error(
          `(Home) Failed to retrieve search results for query ${inputText}`
        );
        setValidationState({
          validationType: 'error',
          validationContent: 'Failed to retrieve search results',
        });
      }
    },
    [onThrottledInputChange, setOptions, user]
  );

  const fetchPersonalRoomInfo = useCallback(
    async (uuid: number) => {
      const response = await getPersonalRoom(uuid, user.loginJwt);

      if ('error' in response) {
        throw new Error(
          `(Home) Failed to get Personal Room info, Error = ${response.error}`
        );
      }

      return {
        ...response,
        displayName: `[Personal Room] ${
          (response as GetPersonalRoomResponse).displayName
        }`,
      };
    },
    [user]
  );

  const fetchRoomParticipantCount = useCallback(
    async (roomId: string) => {
      const response = await getParticipantCount(roomId, user.loginJwt);

      if ('error' in response) {
        throw new Error(
          `(Home) Failed to get participants for ${roomId}, Error = ${response.error}`
        );
      }

      return (response as GetParticipantCountResponse).participants;
    },
    [user]
  );

  const onUserSelected = useCallback(
    async function (
      item: DropdownValueOption<ReactText, Record<string, unknown>> | null
    ) {
      setSelectedUser(item);
      setEditMode(false);
      if (!item) {
        return;
      }
      try {
        const personalRoomInfo = (await fetchPersonalRoomInfo(
          +item.value
        )) as RoomInfo;
        const roomParticipantCount = await fetchRoomParticipantCount(
          personalRoomInfo.roomId
        );
        personalRoomInfo.participantCount = roomParticipantCount;

        if (+item.value === +user.uuid && !userPersonalRoom.current) {
          userPersonalRoom.current = personalRoomInfo;
        }

        setSelectedRoom(personalRoomInfo);
        setValidationState({
          validationType: null,
          validationContent: '',
        });
      } catch (e) {
        setSelectedRoom({ roomId: '', displayName: '', participantCount: 0 });
        setValidationState({
          validationType: 'error',
          validationContent: 'Failed to retrieve Personal Room information',
        });
        logger.error((e as Error).message);
      }
    },
    [
      fetchPersonalRoomInfo,
      fetchRoomParticipantCount,
      setSelectedRoom,
      user.uuid,
    ]
  );

  const onInputBoxClicked = () => {
    if (!initialLoadDone) {
      return;
    }
    setSelectedRoom({ roomId: '', displayName: '', participantCount: 0 });
    setSelectedUser(null);
    setValidationState({
      validationType: null,
      validationContent: '',
    });
    setEditMode(true);
    // this will trigger the useEffect hook to set focus to the AutoComplete component
  };

  const onBlur = async () => {
    // If the user clears the input search box and focuses out, replace it with their personal room.
    if (!autoCompleteElement.current?.value) {
      if (userPersonalRoom.current) {
        setSelectedUser({ value: +user.uuid, label: '' });
        setSelectedRoom(userPersonalRoom.current);
        setEditMode(false);
      } else {
        await onUserSelected({ value: +user.uuid, label: '' });
      }
    }
  };

  useEffect(() => {
    if (editMode) {
      autoCompleteElement.current?.focus();
    }
  }, [editMode]);

  useEffect(() => {
    (async () => {
      // on page load, fetch & show current user's personal room
      await onUserSelected({ value: +user.uuid, label: '' });
      setInitialLoadDone(true);
    })();
  }, [user, onUserSelected]);

  useEffect(() => {
    if (selectedRoom) {
      const updateParticipantCount = async () => {
        try {
          const participantCount = await fetchRoomParticipantCount(
            selectedRoom.roomId
          );
          setSelectedRoom({ ...selectedRoom, participantCount });
        } catch (e) {
          logger.error(
            `(Home) Failed to fetch participant count on focus for Room Id ${selectedRoom.roomId}`
          );
        }
      };

      window.addEventListener('focus', updateParticipantCount);
      return () => window.removeEventListener('focus', updateParticipantCount);
    }
  }, [selectedRoom, setSelectedRoom, fetchRoomParticipantCount]);

  return (
    <RoomNameAutoCompleteContainer>
      <SearchLabel>Search</SearchLabel>
      <FormField {...validationState}>
        <Autocomplete
          placeholder='Search for a Personal Room'
          inputProps={{ 'aria-label': 'Personal Room Name Search' }}
          onThrottledInputChange={onInput}
          showDividers
          style={{
            width: isTablet ? 'calc(100vw - 100px)' : 400,
            display: editMode ? 'block' : 'none',
          }}
          selectedItem={selectedUser}
          onItemSelect={onUserSelected}
          inputRef={autoCompleteElement}
          disabled={!editMode}
          onBlur={onBlur}
          optionRenderer={(option, filterText) =>
            isTablet ? (
              <RoomNameAutoCompleteDropdownRowMobile>
                <RoomNameAutoCompleteDropdownItemCss>
                  {stringToHighlightedLabel(option.label, filterText)}
                </RoomNameAutoCompleteDropdownItemCss>
                <RoomNameAutoCompleteDropdownItemCss>{`Personal Room (${option.meta?.firm})`}</RoomNameAutoCompleteDropdownItemCss>
              </RoomNameAutoCompleteDropdownRowMobile>
            ) : (
              <RoomNameAutoCompleteDropdownRow>
                <RoomNameAutoCompleteDropdownLeftColumn>
                  {stringToHighlightedLabel(option.label, filterText)}
                </RoomNameAutoCompleteDropdownLeftColumn>
                <RoomNameAutoCompleteDropdownRightColumn>{`Personal Room (${option.meta?.firm})`}</RoomNameAutoCompleteDropdownRightColumn>
              </RoomNameAutoCompleteDropdownRow>
            )
          }
          {...rest}
        />

        {/* TODO: Remove this workaround when a renderer prop for selected value is exposed https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/issues/1738 */}
        {/* The <InputGroup> element shows the actual ROOM names, whereas the <Autocomplete> element shows the user's name. */}
        <InputGroup
          style={{
            width: isTablet ? 'calc(100vw - 100px)' : 400,
            display: editMode ? 'none' : 'flex',
          }}
        >
          {selectedRoom && selectedRoom.roomId ? (
            <InputGroup.Addon>
              <CameraIcon
                alt={
                  selectedRoom.participantCount
                    ? 'Room with some participants'
                    : 'Room with no participants'
                }
                src={
                  selectedRoom.participantCount
                    ? '/ic_videocam.svg'
                    : '/ic_videocam_white.svg'
                }
              />
            </InputGroup.Addon>
          ) : null}
          <Input
            placeholder='Search for a Personal Room'
            aria-label='Personal Room Name Search'
            onClick={onInputBoxClicked}
            readOnly
            value={selectedRoom.displayName}
          />
          <InputGroup.Addon>
            <Icon name='x' onClick={onInputBoxClicked} />
          </InputGroup.Addon>
        </InputGroup>
      </FormField>
    </RoomNameAutoCompleteContainer>
  );
}

const RoomNameAutoCompleteContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    .bbui-basedropdown-popper {
      inset: 0 0 auto 0 !important;
      min-width: auto !important;
    }
  }
  .bbui-basedropdown-group-counter {
    padding: 0 4px;
  }
`;
const RoomNameAutoCompleteDropdownRow = styled.div`
  padding: 5px 0;
  display: flex;
`;
const RoomNameAutoCompleteDropdownRowMobile = styled.div`
  width: 100%;
  ${RoomNameAutoCompleteDropdownRow};
  > div {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const RoomNameAutoCompleteDropdownItemCss = styled.div``;
const RoomNameAutoCompleteDropdownLeftColumn = styled.div`
  width: 240px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const RoomNameAutoCompleteDropdownRightColumn = styled.div`
  width: 400px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const SearchLabel = styled.div`
  margin-right: 8px;
`;
const CameraIcon = styled.img`
  height: 16px;
  width: 16px;
  margin: 0 8px;
`;
