import { LogQueue } from '@web-ib/chevre';
import { GlobalLaunchState } from './launchState';

function createLogger() {
  const logger = new LogQueue({
    urlOrUrls: '/api/sendlog',
    level: 3, // INFO
    batchSendIntervalTime: 5000, // 5 seconds
    internalLogging: false,
    extractTimeFromLog: true,
    customHeaders: {
      'X-App-Instance-Id': GlobalLaunchState.appInstanceId,
      'X-App-Mode': GlobalLaunchState.appMode,
    },
  });

  /*
    Expose the logger as a global variable to help with debugging and development. (e.g window.logger = logger)
    In addition to verifying the logger configurations, the following are other useful fields and methods:
      logger.allLogsQueue - All logs captured.
      logger.sendingQueue - Logs in the queue waiting to be sent.
      logger.send() - Manually send logs in queue.
   */

  window.addEventListener('error', (e) => {
    logger.error(`Detected "error" event: ${e.message}`);
  });

  window.addEventListener('unhandledrejection', (e) => {
    logger.error(`Detected "unhandledrejection" event: ${e.reason}`);
  });

  return logger;
}

export const logger = createLogger();
