import { v4 as uuidv4 } from 'uuid';
import { fetchWithRetryAndTimeout } from './fetchImproved';
import { logger } from './logger';
import {
  getBssoAccessTokenFromTerminalConnect,
  loginWithBssoAccessToken,
} from './terminalConnect';
import { createBssoOpenTokenUrl } from './openToken';
import { safeDecodeJwt, safeParseResponseAsJson } from './util';
import {
  RequestMethod,
  RequestParams,
  HttpResponse,
  Response,
  PhoneAPIResponseType,
  PhoneNumberDetail,
} from '../types';
import { GlobalLaunchState } from './launchState';

// See response status code and description at https://bbgithub.dev.bloomberg.com/bb-room/jitsi-management-service/blob/develop/service-api.yaml

export async function getRoomAuth(
  roomId: string,
  loginJwt: string
): Promise<Response> {
  return request({
    url: `/api/rooms/${roomId}/authorize`,
    bearerToken: loginJwt,
    roomId,
  });
}

export async function getRoom(
  roomId: string,
  loginJwt: string
): Promise<Response> {
  return request({
    url: `/api/rooms/${roomId}`,
    bearerToken: loginJwt,
    roomId,
  });
}

export async function createRoom(loginJwt: string): Promise<Response> {
  return request({
    url: '/api/rooms',
    method: RequestMethod.post,
    bearerToken: loginJwt,
  });
}

export async function getUserIbChats(
  loginJwt: string,
  fetchConferences: boolean
): Promise<Response> {
  return request({
    url: `/api/user/ibchats${fetchConferences ? '?conferences=true' : ''}`,
    bearerToken: loginJwt,
  });
}

export async function getUserInvitations(
  loginJwt: string,
  since: string
): Promise<Response> {
  return request({
    url: `/api/user/invitations?since=${since}`,
    bearerToken: loginJwt,
  });
}

type Invitee = { bpi: string };

export async function createInvitations(
  roomId: string,
  invitees: Invitee[],
  loginJwt: string
): Promise<Response> {
  return request({
    url: `/api/rooms/${roomId}/invitations`,
    method: RequestMethod.post,
    bearerToken: loginJwt,
    body: JSON.stringify({
      invitees,
    }),
  });
}

export async function getIbRoom(
  ibId: string,
  ibType: string,
  ibTitle: string,
  loginJwt: string
) {
  return request({
    url: `/api/rooms/ib/${ibId}`,
    method: RequestMethod.post,
    bearerToken: loginJwt,
    body: JSON.stringify({
      chatRoomType: ibType,
      // Providing room title will set the displayName correctly (otherwise, it defaults to 'IB Conference')
      chatRoomTitle: ibTitle,
    }),
  });
}

export async function getIbGenericPetToken(
  loginJwt: string
): Promise<Response> {
  return request({ url: `/api/ib/cistoken/login`, bearerToken: loginJwt });
}

export async function getRoomParticipants(
  roomId: string,
  roomAuthJwt: string
): Promise<Response> {
  return request({
    url: `/api/rooms/${roomId}/participants`,
    bearerToken: roomAuthJwt,
    roomId,
  });
}

export async function getIbChatMembers(
  chatId: string,
  loginJwt: string
): Promise<Response> {
  const response = await request({
    url: `/api/ib/chats/${chatId}?participants=true`,
    bearerToken: loginJwt,
  });
  return 'error' in response
    ? response
    : (response as { [key: string]: any }).chatRoomParticipants || [];
}

export async function getShard(
  roomId: string,
  loginJwt: string
): Promise<Response> {
  return request({
    url: `/shard?room=${roomId}`,
    bearerToken: loginJwt,
    roomId,
  });
}

export async function getIceServers(loginJwt: string): Promise<Response> {
  return request({
    url: `/api/iceServers`,
    bearerToken: loginJwt,
  });
}

export async function getPersonalRoom(
  uuid: number,
  loginJwt: string
): Promise<Response> {
  return request({
    url: `/api/rooms/personal/${uuid}`,
    method: RequestMethod.post,
    bearerToken: loginJwt,
  });
}

export async function getUsersAutoComplete(query: string, loginJwt: string) {
  return request({
    url: `/api/autocomplete/users?q=${encodeURIComponent(query)}`,
    method: RequestMethod.get,
    bearerToken: loginJwt,
  });
}

async function request({
  url,
  method = RequestMethod.get,
  bearerToken,
  body,
  roomId,
}: RequestParams): Promise<Response> {
  const traceId = uuidv4();
  logger.info(`NETWORK [REQUEST ${traceId}]: ${method} ${url}`);

  const headers: { [key: string]: string } = {
    authorization: `Bearer ${bearerToken}`,
    'Content-Type': 'application/json',
    'X-Trace-Id': traceId,
    'X-App-Instance-Id': GlobalLaunchState.appInstanceId,
    'X-App-Mode': GlobalLaunchState.appMode,
  };

  const decodedToken: any = safeDecodeJwt(bearerToken);
  if (decodedToken?.uuid) {
    headers['X-User-Id'] = decodedToken?.uuid;
  }

  if (roomId) {
    headers['X-Room-Id'] = roomId;
  }

  const res = await fetchWithRetryAndTimeout({
    url,
    fetchOptions: {
      method,
      headers,
      body,
    },
    maxAttempts: 4,
    delayRetry: (retry: number) => Math.pow(2, retry - 1) * 1000,
    timeout: 7000,
    retryCondition: (res: HttpResponse) =>
      res && [503, 504].includes(res.status),
    onRetry: ({
      response,
      error,
      attempt,
    }: {
      response: HttpResponse;
      error: string;
      attempt: number;
    }) =>
      logger.error(
        `NETWORK [RETRY ${traceId}]: (Previous) ${method} ${url} ${response?.status} ${error} attempt ${attempt}`
      ),
    onAborted: (attempt: number) =>
      logger.error(
        `NETWORK [ABORTED ${traceId}]: ${method} ${url} attempt ${attempt}`
      ),
    onAllRetriesFailed: () => {
      logger.error(`NETWORK [ALL TRIES FAILED ${traceId}]: ${method} ${url}`);
      return {
        error: 'All retries failed.',
      };
    },
  });

  let data, error, responseCode: any;

  if (!res) {
    error = 'Unknown error.';
  } else if (res.ok) {
    data = safeParseResponseAsJson(res);
  } else {
    responseCode = res.status;
    switch (res.status) {
      case 401:
        error = 'Unauthorized user.';
        break;
      case 404:
        error = 'Invalid room or unauthorized user.';
        break;
      default:
        error = 'Unknown error.';
        break;
    }
  }

  if (error) {
    logger.error(
      `NETWORK [RESPONSE ${traceId}]: ${method} ${url} Error - ${error}`
    );
    return { error, responseCode };
  } else {
    logger.info(
      `NETWORK [RESPONSE ${traceId}]: ${method} ${url} ${res.status}`
    );
    return data;
  }
}

export function login(): void {
  // TODO: Make `login` function async instead of using this IIFE after we upgrade React Router.
  (async () => {
    if (GlobalLaunchState.shortLivedBssoOpenToken) {
      // Try with the BSSO OpenToken from the launch state, if available
      window.location.href = createBssoOpenTokenUrl(
        GlobalLaunchState.shortLivedBssoOpenToken
      );
    } else {
      const bssoAccessToken = await getBssoAccessTokenFromTerminalConnect();
      if (
        bssoAccessToken &&
        (await loginWithBssoAccessToken(bssoAccessToken))
      ) {
        // Requires a page reload for the app to realize that a login cookie was created.
        // This method retains the original query params in the URL preserving the launch state.
        // TODO:
        // Use CookieStore API for detecting cookie changes when more browsers support it
        // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Cookie_Store_API
        window.location.reload();
      } else {
        // Try regular BSSO OIDC login flow if other methods fail
        window.location.href = '/api/login/oauth2';
      }
    }
  })();
}

export function logout(): void {
  window.location.href = '/api/logout';
}

export function gotoRoom(roomId: string): void {
  window.location.href = `/rooms/${roomId}`;
}

export async function getPhoneNumbers(
  loginJwt: string,
  roomId: string
): Promise<PhoneNumberDetail[]> {
  const libphonenumber = import('libphonenumber-js');

  const phoneAPIResponse = (await request({
    url: `/api/rooms/${roomId}/?dialInInfo=true`,
    bearerToken: loginJwt,
  })) as PhoneAPIResponseType;

  if ('error' in phoneAPIResponse) {
    logger.error(`DIAL-IN ERROR: ${phoneAPIResponse}`);
    return [];
  } else {
    const { parsePhoneNumber } = await libphonenumber;
    return phoneAPIResponse.dialInInfo.map((p) => ({
      id: p.countryCode,
      number: parsePhoneNumber(p.phoneNumber)
        .formatInternational()
        .replace(/\s/g, '-'),
      ext: p.phoneExtension,
      pin: p.phonePin,
    }));
  }
}

export async function placeClickToDialCall(
  loginJwt: string,
  callerPhone: string,
  calleePhone: string,
  calleeExtension: string
): Promise<boolean> {
  const phoneAPIResponse = await request({
    url: `/api/phonecall`,
    method: RequestMethod.post,
    bearerToken: loginJwt,
    body: JSON.stringify({
      callerPhone: callerPhone,
      calleePhone: calleePhone,
      calleeExtension: calleeExtension,
    }),
  });

  if ('error' in phoneAPIResponse) {
    logger.error(`PLACE CALL ERROR: ${phoneAPIResponse}`);
    return false; // Any error from backend means call wasn't placed
  }

  return true; // Status 200 from JMS indicates bfonsvc2 ack
}

export async function getParticipantCount(roomId: string, loginJwt: string) {
  return request({
    url: `/api/rooms/${roomId}/participantCount`,
    bearerToken: loginJwt,
  });
}
