import React, { useEffect } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css';
import styled from 'styled-components';
import { useNotification } from '@bbnpm/bb-ui-framework';
import { logger } from '../common/logger';
import { GlobalLaunchState } from '../common/launchState';
import { HOME_PATH } from '../common/paths';
import { isIbBasedRoom, isTransientIbBasedRoom, isValidIbChatId } from './util';
import { copyToClipboard, sanitizeUrl } from '../common/util';
import {
  useUpdateFullScreenStatusToJitsi,
  useCatchFullScreenSignalFromJitsi,
} from './fullScreen';
import useBBUINotifications from './notifications';
import { inviteUserToRoom } from '../common/invitation';

export default function Jitsi({
  user,
  roomAuth,
  jitsiApi,
  setJitsiApi,
  setSpeaker,
  setRoomJoined,
  roomJoined,
  setIbOpen,
  setParticipantsPaneOpen,
  setPollsPaneOpen,
  openDialInModal,
  shard,
  onRecorderChanged,
  onRecordingStatusChanged,
  onHandRaised,
  onScreenShareStatusChanged,
  onPinnedScreenShareChanged,
  onSelfScreenShareChanged,
  onContextMessage,
}) {
  const { iceServers } = useRouteLoaderData('rooms-router');
  useSetupJitsi(roomAuth, user, setJitsiApi, shard, iceServers);
  useSetRoomJoined(jitsiApi, setRoomJoined);
  useLogJoinRoom(jitsiApi, roomAuth);
  useEnsureUserName(jitsiApi, user);
  useEnsureUserEmail(jitsiApi, user);
  useHandleLeaveRoom(jitsiApi);
  useHandleKickOut(jitsiApi, roomAuth);
  useEnableNoiseSuppression(jitsiApi, roomJoined);
  useSetDominantSpeaker(jitsiApi, setSpeaker);
  useRecorderDispatch(jitsiApi, onRecorderChanged);
  useRecordingStatusDispatch(jitsiApi, onRecordingStatusChanged);
  useHandRaisedDispatch(jitsiApi, onHandRaised);
  useParticipantLeftDispatch(jitsiApi, onHandRaised);
  useScreenShareStatusChange(jitsiApi, onScreenShareStatusChanged);
  usePinParticipantChange(jitsiApi, onPinnedScreenShareChanged);
  useSelfScreenShareStatusChange(
    jitsiApi,
    onSelfScreenShareChanged,
    onPinnedScreenShareChanged
  );
  useCustomLog(jitsiApi, roomAuth);
  useDisposeJitsiApiOnComponentUnmount(jitsiApi);
  useToggleIB(jitsiApi, setIbOpen);
  useToggleParticipantsPane(jitsiApi, setParticipantsPaneOpen);
  useTogglePollsPane(jitsiApi, setPollsPaneOpen);
  useCatchFullScreenSignalFromJitsi(jitsiApi);
  useOpenDialInModal(jitsiApi, openDialInModal);
  useCopyMeetingUrl(jitsiApi, copyToClipboard);
  useJoinFromAnotherDevice(jitsiApi, user, roomAuth, inviteUserToRoom);
  useUpdateFullScreenStatusToJitsi(jitsiApi);
  useBBUINotifications(jitsiApi, onContextMessage);

  return (
    <JitsiIframeContainer
      id={JITSI_IFRAME_CONTAINER_ID}
      ratio={roomJoined ? '' : '4/3'}
      $roomJoined={roomJoined}
    ></JitsiIframeContainer>
  );
}

function useSetupJitsi(roomAuth, user, setJitsiApi, shard, iceServers) {
  useEffect(() => {
    if (roomAuth && roomAuth.room && user && shard && iceServers) {
      const configOverwrite = {
        deploymentInfo: { shard },
        // 'enableBBDialIn' is our custom configuration option
        enableBBDialIn: user.dialInEnabled,
        // 'enableBBVhub' is our custom configuration option
        enableBBVhub: user.vhubEnabled,
        // 'enableDefaultChat' is our custom configuration option
        enableDefaultChat:
          !isIbBasedRoom(roomAuth.room.roomType) ||
          !isValidIbChatId(roomAuth.room.ibChatId),
        localRecording: {
          notifyAllParticipants: true,
          disableSelfRecording: true,
          disable: true,
        },
        liveStreaming: {
          enabled: false,
        },
        recordingService: {
          enabled: false,
          sharingEnabled: false,
          hideStorageWarning: true,
        },
        websocketKeepAliveUrl: `/shard?room=${roomAuth.room.roomId}&token=${user.loginJwt}`,
      };

      // Disable watchRTC for clients
      if (!user.isInternalUser) {
        configOverwrite.analytics = {
          ...configOverwrite.analytics,
          watchrtcAPIKey: '',
        };
      }

      // Make Jitsi iFrame subject match the title determined by ROOM backend
      configOverwrite.localSubject = roomAuth.room.displayName;
      // Transient IB chats have a different title per user; skip setting it for everyone as a moderator
      if (!isTransientIbBasedRoom(roomAuth.room.roomType)) {
        configOverwrite.subject = roomAuth.room.displayName;
      }

      const toolbarButtons = [];

      // Enable recording for users with recording permissions
      if (user.localRecordingEnabled || user.serverSideRecordingEnabled) {
        toolbarButtons.push('recording');
        configOverwrite.localRecording.disable = !user.localRecordingEnabled;
        configOverwrite.recordingService.enabled =
          !!user.serverSideRecordingEnabled;
      }

      // 'toolbarButtons' are merged with the rest of the buttons enabled in config.js of the deployment
      configOverwrite.toolbarButtons = toolbarButtons;

      const jitsiContainer = document.getElementById(JITSI_IFRAME_CONTAINER_ID);
      if (GlobalLaunchState.isLocal) {
        // On dev builds of React, hooks may be run twice to ensure they are idempotent
        // When that happens, we will end up with 2 Jitsi iframes, so one needs to be removed
        // This doesn't happen on prod builds of React
        const oldJitsiIframe = jitsiContainer.querySelector('iframe');
        if (oldJitsiIframe) oldJitsiIframe.remove();
      }

      setJitsiApi(
        new window.JitsiMeetExternalAPI(
          GlobalLaunchState.isLocal
            ? import.meta.env.VITE_JITSI_HOST_URL
            : window.location.host,
          {
            parentNode: jitsiContainer,
            jwt: roomAuth.jwt,
            roomName: roomAuth.room.roomId,
            userInfo: {
              email: user.email,
              displayName: user.fullName,
              userInitials: user.userInitials,
            },
            configOverwrite,
            iceServers: { bbgIceServers: iceServers },
          }
        )
      );
    }
  }, [roomAuth, user, setJitsiApi, shard, iceServers]);
}

function useToggleIB(jitsiApi, setIbOpen) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'chatToggled';
      jitsiApi.addEventListener(event, _setIbOpen);
      return () => jitsiApi.removeEventListener(event, _setIbOpen);
    }
    function _setIbOpen({ enabled }) {
      setIbOpen(enabled);
    }
  }, [jitsiApi, setIbOpen]);
}

function useToggleParticipantsPane(jitsiApi, setParticipantsPaneOpen) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'participantsPaneToggled';
      jitsiApi.addEventListener(event, _setParticipantsPaneOpen);
      return () =>
        jitsiApi.removeEventListener(event, _setParticipantsPaneOpen);
    }
    function _setParticipantsPaneOpen({ open }) {
      setParticipantsPaneOpen(open);
    }
  }, [jitsiApi, setParticipantsPaneOpen]);
}

function useTogglePollsPane(jitsiApi, setPollsPaneOpen) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'pollsPaneToggled';
      jitsiApi.addEventListener(event, _setPollsPaneOpen);
      return () => jitsiApi.removeEventListener(event, _setPollsPaneOpen);
    }
    function _setPollsPaneOpen({ open }) {
      setPollsPaneOpen(open);
    }
  }, [jitsiApi, setPollsPaneOpen]);
}

function useOpenDialInModal(jitsiApi, openDialInModal) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'openDialIn';
      jitsiApi.addEventListener(event, _openDialInModal);
      return () => jitsiApi.removeEventListener(event, _openDialInModal);
    }
    function _openDialInModal() {
      openDialInModal(true);
    }
  }, [jitsiApi, openDialInModal]);
}

function useCopyMeetingUrl(jitsiApi, copyToClipboard) {
  const notification = useNotification();
  useEffect(() => {
    if (jitsiApi) {
      const event = 'copyMeetingUrl';
      jitsiApi.addEventListener(event, _copyToClipboard);
      return () => jitsiApi.removeEventListener(event, _copyToClipboard);
    }
    function _copyToClipboard() {
      copyToClipboard(sanitizeUrl(window.location.href));
      notification.addInfo({
        message: 'Copied meeting web link.',
      });
    }
  }, [jitsiApi, copyToClipboard, notification]);
}

function useJoinFromAnotherDevice(jitsiApi, user, roomAuth, inviteUserToRoom) {
  const notification = useNotification();
  useEffect(() => {
    if (jitsiApi) {
      const event = 'joinFromAnotherDevice';
      jitsiApi.addEventListener(event, _joinFromAnotherDevice);
      return () => jitsiApi.removeEventListener(event, _joinFromAnotherDevice);
    }
    function _joinFromAnotherDevice() {
      inviteUserToRoom(user.uuid, roomAuth.room.roomId, user.loginJwt);
      notification.addInfo({
        message:
          'You can now join this meeting on another device at blproom.com.',
      });
    }
  }, [jitsiApi, inviteUserToRoom, user, roomAuth, notification]);
}

function useEnableNoiseSuppression(jitsiApi, roomJoined) {
  useEffect(() => {
    if (jitsiApi) {
      const audioInputChanged = 'audioInputDeviceChanged';
      const audioTrackMutedChanged = 'audioTrackMutedChanged';
      const conferenceJoined = 'videoConferenceJoined';

      jitsiApi.addEventListener(audioInputChanged, handleAudioInputChanged);
      jitsiApi.addEventListener(
        audioTrackMutedChanged,
        handleAudioTrackMutedChanged
      );
      jitsiApi.addEventListener(conferenceJoined, handleConferenceJoined);

      return () => {
        jitsiApi.removeEventListener(
          audioInputChanged,
          handleAudioInputChanged
        );
        jitsiApi.removeEventListener(
          audioTrackMutedChanged,
          handleAudioTrackMutedChanged
        );
        jitsiApi.removeEventListener(conferenceJoined, handleConferenceJoined);
      };
    }

    function enableNoiseSuppression() {
      logger.info('Automatically enabling noise suppression');
      jitsiApi.executeCommand('setNoiseSuppressionEnabled', { enabled: true });
    }

    function handleAudioInputChanged({ noiseSuppressionSettingEnabled }) {
      // An audio input change event ensures there is already a local audio track created.
      // Try to enable noise suprression on the new audio input device.
      if (noiseSuppressionSettingEnabled) enableNoiseSuppression();
    }

    function handleAudioTrackMutedChanged({
      muted,
      noiseSuppressionSettingEnabled,
    }) {
      if (noiseSuppressionSettingEnabled && !muted) enableNoiseSuppression();
    }

    function handleConferenceJoined({
      isLocalTrackMuted,
      noiseSuppressionSettingEnabled,
    }) {
      if (noiseSuppressionSettingEnabled && !isLocalTrackMuted)
        enableNoiseSuppression();
    }
  }, [jitsiApi, roomJoined]);
}

function useSetRoomJoined(jitsiApi, setRoomJoined) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'videoConferenceJoined';
      jitsiApi.addEventListener(event, _setJoinTime);
      return () => jitsiApi.removeEventListener(event, _setJoinTime);
    }
    function _setJoinTime() {
      setRoomJoined(true);
    }
  }, [jitsiApi, setRoomJoined]);
}

function useLogJoinRoom(jitsiApi, roomAuth) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'videoConferenceJoined';
      jitsiApi.addEventListener(event, logJoin);
      return () => jitsiApi.removeEventListener(event, logJoin);
    }
    function logJoin() {
      logger.info(
        `(Joined Video Conference) Associated IB Chat ID [${roomAuth.room.ibChatId}]`
      );
    }
  }, [jitsiApi, roomAuth]);
}

function useEnsureUserName(jitsiApi, user) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'displayNameChange';
      jitsiApi.addEventListener(event, ensureUserDisplayName);
      return () => jitsiApi.removeEventListener(event, ensureUserDisplayName);
    }

    function ensureUserDisplayName({ displayname }) {
      const userDisplayName = user.fullName;
      if (displayname !== userDisplayName) {
        jitsiApi.executeCommand('displayName', userDisplayName);
      }
    }
  }, [jitsiApi, user]);
}

function useEnsureUserEmail(jitsiApi, user) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'emailChange';
      jitsiApi.addEventListener(event, ensureUserEmail);
      return () => jitsiApi.removeEventListener(event, ensureUserEmail);
    }

    function ensureUserEmail({ email }) {
      if (email !== user.email) {
        jitsiApi.executeCommand('email', user.email);
      }
    }
  }, [jitsiApi, user]);
}

function useHandleKickOut(jitsiApi, roomAuth) {
  const notification = useNotification();

  useEffect(() => {
    if (jitsiApi) {
      const event = 'participantKickedOut';
      jitsiApi.addEventListener(event, onKickOut);

      return () => jitsiApi.removeEventListener(event, onKickOut);
    }

    function onKickOut({ kicked, kicker }) {
      if (kicked.local) {
        const meetingUrl = window.location.href.replace(
          window.location.hash,
          ''
        );
        const roomName = roomAuth?.room.displayName;

        notification.addWarning({
          message: (
            <span>
              {kicker.name} kicked you out of the meeting. Rejoin{' '}
              <a href={meetingUrl}>{roomName}</a>
            </span>
          ),
        });
        jitsiApi.executeCommand('hangup');
      }
    }
  }, [jitsiApi, roomAuth, notification]);
}

function useHandleLeaveRoom(jitsiApi) {
  const navigate = useNavigate();
  useEffect(() => {
    if (jitsiApi) {
      const event = 'readyToClose';
      jitsiApi.addEventListener(event, onLeaveRoom);
      return () => jitsiApi.removeEventListener(event, onLeaveRoom);
    }

    function onLeaveRoom() {
      navigate(HOME_PATH);
    }
  }, [jitsiApi, navigate]);
}

function useSetDominantSpeaker(jitsiApi, setSpeaker) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'dominantSpeakerChanged';
      jitsiApi.addEventListener(event, _setSpeaker);
      return () => jitsiApi.removeEventListener(event, _setSpeaker);
    }

    function _setSpeaker({ id, silence }) {
      if (!silence) {
        const participant = jitsiApi
          .getParticipantsInfo()
          .find((p) => p.participantId === id);

        setSpeaker({
          name: participant?.displayName || 'Bloomberg User',
          userInitials: participant?.userInitials || '',
          avatar: jitsiApi.getAvatarURL(id),
        });
      } else {
        // Dominant speaker is silent
        setSpeaker(undefined);
      }
    }
  }, [jitsiApi, setSpeaker]);
}

function useRecorderDispatch(jitsiApi, onRecorderChanged) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'recorderChanged';
      jitsiApi.addEventListener(event, handleRecorderChanged);
      return () => jitsiApi.removeEventListener(event, handleRecorderChanged);
    }

    function handleRecorderChanged({ id, isRecording }) {
      const name =
        jitsiApi.getParticipantsInfo().find((p) => p.participantId === id)
          ?.displayName || 'Bloomberg User';
      onRecorderChanged({ id, name, isRecording });
    }
  }, [jitsiApi, onRecorderChanged]);
}

function useRecordingStatusDispatch(jitsiApi, onRecordingStatusChanged) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'recordingStatusChanged';
      jitsiApi.addEventListener(event, handleRecordingStatusChanged);
      return () =>
        jitsiApi.removeEventListener(event, handleRecordingStatusChanged);
    }

    function handleRecordingStatusChanged({ on, error }) {
      onRecordingStatusChanged({ on, error });
    }
  }, [jitsiApi, onRecordingStatusChanged]);
}

function useHandRaisedDispatch(jitsiApi, onHandRaised) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'raiseHandUpdated';
      jitsiApi.addEventListener(event, handleHandRaised);
      return () => jitsiApi.removeEventListener(event, handleHandRaised);
    }

    function handleHandRaised({ id, handRaised }) {
      const name =
        jitsiApi.getParticipantsInfo().find((p) => p.participantId === id)
          ?.displayName || 'Bloomberg User';
      onHandRaised({ id, name, handRaised });
    }
  }, [jitsiApi, onHandRaised]);
}

function useParticipantLeftDispatch(jitsiApi, onHandRaised) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'participantLeft';
      jitsiApi.addEventListener(event, handleParticipantLeft);
      return () => jitsiApi.removeEventListener(event, handleParticipantLeft);
    }

    function handleParticipantLeft({ id }) {
      const name = null;
      const handRaised = false;
      onHandRaised({ id, name, handRaised });
    }
  }, [jitsiApi, onHandRaised]);
}

function useSelfScreenShareStatusChange(jitsiApi, onSelfScreenShareChanged) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'screenSharingStatusChanged';
      jitsiApi.addEventListener(event, handleScreenShareStatusChange);
      return () =>
        jitsiApi.removeEventListener(event, handleScreenShareStatusChange);
    }

    function handleScreenShareStatusChange({ on, details }) {
      onSelfScreenShareChanged({
        sharing: on,
        shareID: details.sourceID,
      });
    }
  }, [jitsiApi, onSelfScreenShareChanged]);
}

function useScreenShareStatusChange(jitsiApi, onScreenShareStatusChanged) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'remoteScreenShareStatusChanged';
      jitsiApi.addEventListener(event, handleScreenShareStatusChange);
      return () =>
        jitsiApi.removeEventListener(event, handleScreenShareStatusChange);
    }

    function handleScreenShareStatusChange({ screenSharers }) {
      onScreenShareStatusChanged(screenSharers);
    }
  }, [jitsiApi, onScreenShareStatusChanged]);
}

function usePinParticipantChange(jitsiApi, onPinnedScreenShareChanged) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'pinnedParticipantChanged';
      jitsiApi.addEventListener(event, handlePinParticipantChange);
      return () =>
        jitsiApi.removeEventListener(event, handlePinParticipantChange);
    }

    function handlePinParticipantChange({ id }) {
      onPinnedScreenShareChanged(id);
    }
  }, [jitsiApi, onPinnedScreenShareChanged]);
}

function useCustomLog(jitsiApi, roomAuth) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'log';
      jitsiApi.addEventListener(event, customLog);
      return () => jitsiApi.removeEventListener(event, customLog);
    }

    function customLog({ logLevel, args }) {
      const logMessage = `(Jitsi) ${args.join(' ')}`.replace(/\n/g, '  ');

      if (logger[logLevel]) {
        logger[logLevel](logMessage);
      } else if (logLevel === 'log') {
        logger.info(logMessage);
      } else {
        logger.info(
          `(Jitsi) Invalid log level "${logLevel}", defaulting to log level: info`
        );
        logger.info(logMessage);
      }
    }
  }, [jitsiApi, roomAuth]);
}

function useDisposeJitsiApiOnComponentUnmount(jitsiApi) {
  useEffect(() => {
    return () => {
      if (jitsiApi) {
        jitsiApi.dispose();
      }
    };
  }, [jitsiApi]);
}

const JitsiIframeContainer = styled(AspectRatio)`
  height: ${({ $roomJoined }) => ($roomJoined ? '100%' : '80%')};
`;

const JITSI_IFRAME_CONTAINER_ID = 'jitsi-container';
