import { Modal, Button } from '@bbnpm/bb-ui-framework';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  jitsiApi: any;
}

export default function RecordingMsgModal({
  isOpen,
  setIsOpen,
  jitsiApi,
}: Props) {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      title='This meeting is being recorded'
      contentLabel='This meeting is being recorded'
      portalContainerId='root'
      onClose={() => setIsOpen(false)}
      primaryActions={
        <>
          <Button kind='primary' onClick={() => setIsOpen(false)}>
            Stay
          </Button>
          <Button
            kind='secondary'
            onClick={() => jitsiApi.executeCommand('hangup')}
          >
            Leave Meeting
          </Button>
        </>
      }
    >
      <div>
        <div>{recordedP1Text}</div>
      </div>
    </Modal>
  );
}

const recordedP1Text =
  'By staying in this meeting, you consent to being recorded. The meeting host is recording audio and on-screen \
  portions of this meeting. The purpose of recording is to make such recording available to Bloomberg employees, \
  clients and third parties as authorized by relevant personnel, and for legitimate business purposes related to \
  internal business, product development, and quality control. By participating in this meeting, you consent to \
  use of your statements, likeness and images for these purposes. We may collect or process personal information \
  to identify you. Personal information and recordings may be transferred to jurisdictions with data protection \
  laws that may differ from the data protection laws in your jurisdiction. You relinquish any rights to the \
  recording and understand the recording may be copied and used. Bloomberg and its affiliates shall have no \
  liability or responsibility for the security or maintenance of the recording or other information you provide. \
  If you do not wish to be recorded, please disconnect or hang up.';
