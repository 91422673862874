import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown } from '@bbnpm/bb-ui-framework';
import { detect } from 'detect-browser';
import { TestCaseMessage, TestCase } from './common';
import { TestWebRTCContext } from './TestWebRTCContext';

export default function SelectDevices() {
  const { mic, setMic, cam, setCam, speaker, setSpeaker, setStream } =
    useContext(TestWebRTCContext);
  const [error, setError] = useState('');
  const streamRef = useRef(null);

  const mics = useGetDevices('audioinput', setError);
  const speakers = useGetDevices('audiooutput', setError);
  const cams = useGetDevices('videoinput', setError);

  useGetDefaultMic(mics, setMic);
  useGetDefaultSpeaker(speakers, setSpeaker);
  useGetDefaultCam(cams, setCam);

  useGetMicCamStream(mic, cam, setStream, streamRef, setError);

  return (
    <TestCase>
      <table>
        <colgroup>
          <col style={{ width: '90px' }} />
          <col style={{ width: '350px' }} />
        </colgroup>
        <tbody>
          <tr>
            <td>Microphone</td>
            <td>
              <Dropdown
                options={devicesToDropdownOptions(mics)}
                onItemSelect={({ value }) =>
                  setMic(mics.find((d) => d.deviceId === value))
                }
                value={mic?.deviceId}
              />
            </td>
          </tr>
          <tr>
            <td>Speaker</td>
            <td>
              <Dropdown
                options={devicesToDropdownOptions(speakers)}
                onItemSelect={({ value }) =>
                  setSpeaker(speakers.find((d) => d.deviceId === value))
                }
                value={speaker?.deviceId}
              />
            </td>
          </tr>
          <tr>
            <td>Camera</td>
            <td>
              <Dropdown
                options={devicesToDropdownOptions(cams)}
                onItemSelect={({ value }) =>
                  setCam(cams.find((d) => d.deviceId === value))
                }
                value={cam?.deviceId}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <TestCaseMessage
        instructionParagraphs={[
          ...[
            'Select devices used for all the tests in this page.',
            'Refresh page to reflect device install/uninstall.',
          ],
          // https://stackoverflow.com/questions/41569241/webrtc-firefox-does-not-detect-any-output-devices
          detect().name === 'firefox' &&
            'No speakers in Firefox? Try set media.setsinkid.enabled to true in about:config.',
        ]}
        error={error}
      />
    </TestCase>
  );
}

function devicesToDropdownOptions(devices) {
  return devices
    .map(({ label, deviceId }) => ({
      label,
      value: deviceId,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

function useGetMicCamStream(mic, cam, setStream, streamRef, setError) {
  useEffect(() => {
    if (mic || cam) {
      getStream(mic, cam);
    }

    async function getStream(mic, cam) {
      const options = {
        audio: {
          deviceId: mic ? { exact: mic.deviceId } : undefined,
        },
        video: {
          deviceId: cam ? { exact: cam.deviceId } : undefined,
        },
      };
      try {
        // Stop existing stream tracks before getUserMedia for Firefox issue
        // https://stackoverflow.com/questions/59068146/navigator-mediadevices-getusermedia-api-rejecting-with-error-notreadableerror
        streamRef.current?.getTracks().forEach((t) => t.stop());
        const stream = await navigator.mediaDevices.getUserMedia(options);
        streamRef.current = stream;
        setStream(stream);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    }
  }, [mic, cam, setStream, streamRef, setError]);
}

function useGetDevices(deviceType, setError) {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    getDevices();

    async function getDevices() {
      try {
        await promptIfNoDevicePermission();
        const devices = await navigator.mediaDevices.enumerateDevices();
        const devicesOfKind = devices
          .filter((d) => d.kind === deviceType)
          .map((d) => ({
            deviceId: d.deviceId,
            label: d.label,
          }));
        setDevices(devicesOfKind);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }
  }, [deviceType, setError]);

  return devices;
}

function useGetDefaultMic(mics, setMic) {
  useEffect(() => {
    if (mics.length) {
      setMic(mics[0]);
    }
  }, [mics, setMic]);
}

function useGetDefaultSpeaker(speakers, setSpeaker) {
  useEffect(() => {
    if (speakers.length) {
      setSpeaker(speakers[0]);
    }
  }, [speakers, setSpeaker]);
}

function useGetDefaultCam(cams, setCam) {
  useEffect(() => {
    if (cams.length) {
      setCam(cams[0]);
    }
  }, [cams, setCam]);
}

async function promptIfNoDevicePermission() {
  await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
}
