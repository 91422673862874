import { useEffect } from 'react';
import { detect, BrowserInfo } from 'detect-browser';

function checkBrowser(): string | void {
  const browser = detect() as BrowserInfo;
  if (browser.os === 'iOS') {
    if (browser.name !== 'ios') {
      return 'Safari is recommended for iOS for the best experience.';
    }
  } else {
    if (!['edge-chromium', 'chrome'].includes(browser.name)) {
      return 'Chrome is recommended for the best experience.';
    }
  }
}

function useCheckBrowser(
  onNotRecommendedBrowser: (browserRecommendation: string) => void
) {
  useEffect(() => {
    const browserRecommendation = checkBrowser();
    if (browserRecommendation) onNotRecommendedBrowser(browserRecommendation);
  }, [onNotRecommendedBrowser]);
}

export default useCheckBrowser;
